import { render, staticRenderFns } from "./CreateForumFinal.vue?vue&type=template&id=19f4cb44&scoped=true&"
import script from "./CreateForumFinal.vue?vue&type=script&lang=js&"
export * from "./CreateForumFinal.vue?vue&type=script&lang=js&"
import style0 from "./CreateForumFinal.vue?vue&type=style&index=0&id=19f4cb44&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f4cb44",
  null
  
)

export default component.exports