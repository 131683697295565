<template>
  <div>
    <router-link class="come-back" to="/admin/forum_final/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование форума №${$route.params.id}` : 'Создание форума' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <div v-if="!isEdit" class="content-sb">
      <p>Основной форум<span>*</span></p>
      <select required class="input blue" v-model="mainForumItem" name="mainForumItem">
        <option v-for="item in mainForumList"
                :key="item.id"
                :value="item.id">{{ item.title }}
        </option>
      </select>
    </div>
    <form @submit.prevent="createForum" ref="createForumFinal" @keypress.enter.prevent>
      <div class="inputs__container">
        <div class="content-sb">
          <p>Название форума<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="forumData.title" type="text" name="title"
                 class="input blue">
        </div>
        <div class="content-sb">
          <p>Округ форума<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="forumData.district" type="text" name="district"
                 class="input blue">
        </div>
        <div class="content-sb">
          <p>Дата начала форума</p>
          <input  placeholder="Введите дату" v-model="forumData.date_start" type="date" name="date_start" class="input blue">
        </div>
        <div class="content-sb">
          <p>Дата окончания форума</p>
          <input  placeholder="Введите дату" v-model="forumData.date_end" type="date" name="date_end" class="input blue">
        </div>
        <div class="content-sb">
          <p>Время окончания форума</p>
          <input  placeholder="23:00" v-model="forumData.time" type="time" name="time" class="input blue">
        </div>
        <div class="content-sb">
          <p>Город<span>*</span></p>
          <input required placeholder="Город" v-model="forumData.location" type="text" name="location" class="input blue">
        </div>

        <div v-if="isEdit" class="content">
          <p>Изображение для страницы форума<span>*</span></p>
          <custom-input-file
            @inputFile="inputImage"
            @deleteFile="removeItem($event, 'image')"
            :uploadedFile="forumData.image"
            :labelPlaceholder="forumData.title"
            :id=imgId />
        </div>
        <div v-else class="content-sb">
          <p>Изображение для страницы форума<span>*</span></p>
          <input
            class="input blue"
            id="image"
            type="file"
            name="image"
          >
        </div>
        <div v-if="isEdit" class="content">
          <p>Баннер для страницы форума</p>
          <custom-input-file
            @inputFile="inputFileBaner"
            @deleteFile="removeItem($event, 'image_forum')"
            :uploadedFile="forumData.forum_image"
            :id=imgId />
        </div>
        <div v-else class="content-sb">
          <p>Баннер для страницы форума</p>
          <input
            class="input blue"
            id="image_forum"
            type="file"
            name="forum_image"
          >
        </div>
        <div class="content">
          <p>O форуме (левый блок)<span>*</span></p>
          <ckeditor :editor-url="$root.editorUrl" v-model="forumData.about_forum_left_block"></ckeditor>
        </div>
        <div class="content">
          <p>O форуме (правый блок)<span>*</span></p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="forumData.about_forum_text"></ckeditor>
        </div>
        <div class="content">
          <p>Программа<span>*</span></p>
          <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="forumData.program_text"></ckeditor>
        </div>
        <div class="content-sb">
          <p>Отображение блок с опросом<span>*</span></p>
          <input v-model="forumData.questions" type="checkbox" name="questions" class="input blue">
        </div>
        <div class="content-sb">
          <p>Открыта регистрация<span>*</span></p>
          <input v-model="forumData.register_open" type="checkbox" name="register_open" class="input blue">
        </div>
        <div class="content-sb">
          <p>Ссылка на опрос<span>*</span></p>
          <input required placeholder="https://oprf.ru" v-model="forumData.program_link" type="text" name="program_link" class="input blue">
        </div>
        <div class="content-sb">
          <p>Ссылка для регистрации<span>*</span></p>
          <input required placeholder="https://oprf.ru" v-model="forumData.url_link" type="text" name="url_link" class="input blue">
        </div>
        <!--
        <div class="content-sb">
          <p>Теги</p>
          <tags-list-search :tags="getTagsList" @setTags="setTags"/>
        </div>
        -->
        <div class="content-sb">
          <p>Новости</p>
          <select name="news" v-model="forumData.news_list" class="input blue" multiple>
            <option v-for="(news,index) in getNews" :value="news.id" :key="`news${index}`">
              {{ news.title }}
            </option>
          </select>
        </div>

        <div v-if="isEdit" class="content">
          <p>Файл</p>
          <custom-input-file
            @inputFile="inputFile"
            @deleteFile="removeItem($event, 'program_url')"
            :uploadedFile="forumData.program_url"
            :id=docId />
        </div>
        <div v-else class="content-sb">
          <p>Файл</p>
          <input
            class="input blue"
            id="photo"
            type="file"
            name="forum_program"
          >
        </div>
        <div class="content-sb">
          <p>Адрес<span>*</span></p>
          <input required placeholder="Введите адрес" v-model="forumData.address" type="text"
                 name="address" class="input blue">
        </div>
        <div class="content-sb">
          <p>Контактный телефон<span>*</span></p>
          <input required placeholder="Введите номер" v-model="forumData.about_forum_phones" type="text"
                 name="about_forum_phones" class="input blue">
        </div>
        <div class="content-sb">
          <p>Электронная почта<span>*</span></p>
          <input required placeholder="Введите e-mail" v-model="forumData.about_forum_emails" type="text"
                 name="about_forum_emails" class="input blue">
        </div>
        <div v-if="isEdit" class="content">
          <p>Спикеры<span>*</span></p>
          <drag-and-drop-speakers/>
        </div>
        <div v-if="isEdit" class="content">
          <p>Партнёры<span>*</span></p>
          <drag-and-drop-final/>
        </div>

      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/forum" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import DragAndDropSpeakers from '@/components/Admin/forum/DragAndDropSpeakers'
import TricolorLine from '@/components/TricolorLine'
import CustomInputFile from '@/components/Admin/CustomInputFile'
// import DragAndDrop from '@/components/Admin/DragAndDropFinal'
import DragAndDropFinal from '@/components/Admin/forum/DragAndDropFinal'

export default {
  name: 'createForumFinal',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      mainForumItem: 1,
      mainForumList: [],
      previewBlob: null,
      previewBlobImg: null,
      docId: 999,
      imgId: 222,
      forumData: {
        district: '',
        title: 'Форум',
        date_start: '',
        date_end: '',
        time: '23:20',
        location: 'Москва',
        about_forum_text: 'О форуме',
        about_forum_left_block: 'Левый блок',
        address: '',
        about_forum_phones: '+7 (495) 221-83-61',
        about_forum_emails: 'forum@oprf.ru',
        program_text: 'Текст программы',
        forum_program: null,
        program_url: '',
        program_title: '',
        program_file: null,
        program_link: '/',
        image: '',
        forum_image: '',
        questions: true,
        register_open: true,
        url_link: '/',
        tag_list: '',
        news_list: []
      },
      forum_image: '',
      partnerData: {
        file: '', // партнёры
        link: '' // партнёры заголовок
      },
      partnersBlock: [],
      contentBlocks: [],
      currentContentBlockType: 0
    }
  },
  components: {
    DragAndDropFinal, // Партнёры
    CustomInputFile,
    TricolorLine,
    DragAndDropSpeakers
    // DragAndDrop
  },
  created () {
    this.$store.commit('setPaginationLimit', null)
    this.$store.commit('setFilterTag', 'Форум «Сообщество»')
    this.$store.dispatch('getPublicNews')
    this.$store.commit('setFilterTag', null)
    this.$store.commit('setPaginationLimit', 9)

    if (this.isEdit) {
      this.$store.dispatch('getCurrentForum', this.$route.params.id)
    } else {
      this.$store.dispatch('getMainForumsList')
    }
  },
  computed: {
    getCurrentItemForum () {
      return this.$store.getters.GET_CURRENT_FORUM
    },
    getMainForumList () {
      return this.$store.getters.GET_MAIN_FORUMS
    },
    getTagsToArray () {
      return this.forumData.tag_list.split(',').filter(tag => !!tag)
    },
    getNews () {
      return this.$store.getters.getPublicNews
    },
    getTagsList () {
      if (this.forumData.tag_list) {
        return this.forumData.tag_list.split('/').filter(tag => !!tag)
      } else {
        return []
      }
    }
  },
  watch: {
    getCurrentItemForum () {
      this.getCurrForumData()
    },
    getMainForumList () {
      this.mainForumList = this.$store.getters.GET_MAIN_FORUMS
    }
  },
  methods: {
    async getCurrForumData () {
      const dataFromServer = await this.$store.getters.GET_CURRENT_FORUM
      this.forumData = {
        title: dataFromServer.title,
        district: dataFromServer.district,
        date_start: dataFromServer.date_start?.split('T')[0] || null,
        date_end: dataFromServer.date_end?.split('T')[0] || null,
        time: dataFromServer.time,
        // date_time: dataFromServer.date_time.split(' ').join('T'),
        location: dataFromServer.location,
        about_forum_text: dataFromServer.about_forum_text,
        about_forum_left_block: dataFromServer.about_forum_left_block,

        about_forum_phones: dataFromServer.about_forum_phones,
        about_forum_emails: dataFromServer.about_forum_emails,
        address: dataFromServer.address,
        program_text: dataFromServer.program_text,
        forum_program: dataFromServer.forum_program,
        program_url: dataFromServer.program_url,
        program_title: dataFromServer.program_title,
        program_file: dataFromServer.program_file,
        program_link: dataFromServer.program_link,
        image: dataFromServer.image,
        forum_image: dataFromServer.forum_image,
        questions: dataFromServer.questions,
        register_open: dataFromServer.register_open,
        url_link: dataFromServer.url_link,
        news_list: dataFromServer.news_list,
        tag_list: dataFromServer.tag_list ? dataFromServer.tag_list.join('/') : ''
        // link: 'link' in dataFromServer.partners[0] ? dataFromServer.partners[0].link : ''
        // system_tags_list: dataFromServer.system_tags_list ? dataFromServer.system_tags_list.join(',') : '',
      }

      this.previewBlob = dataFromServer.partners.map(item => item.url)
      this.partnersBlock = [...this.previewBlob]
      console.log(this.partnersBlock)
      this.$forceUpdate()
    },
    // Добавление одного файла на сервер
    inputImage (event) {
      const formData = new FormData()
      formData.append('image', event.file)
      this.$store.dispatch('updateRegionalForum', { id: this.$route.params.id, data: formData })
    },
    inputFile (event) {
      const formData = new FormData()
      formData.append('program_file', event.file)
      this.$store.dispatch('updateRegionalForum', { id: this.$route.params.id, data: formData })
    },
    inputFileBaner (event) {
      const formData = new FormData()
      formData.append('image_forum', event.file)
      this.$store.dispatch('updateRegionalForum', { id: this.$route.params.id, data: formData })
      // this.forum_image = event.file
    },
    // Удаление файла с сервера
    removeItem (event, value) {
      const formData = new FormData()
      formData.append(value, null)
      this.$store.dispatch('updateRegionalForum', { id: this.$route.params.id, data: formData })
    },
    dragStartHandler (event) {
      console.log(event)
    },
    addPartners () {
      this.previewBlob.push(null)
      console.log(this.previewBlob)
    },
    removeItemPartner (item) {
      console.log(item)
      this.previewBlob.splice(item, 1)
      // this.$forceUpdate()
    },
    previewFiles (event) {
      // console.log(event)
      console.log(event.target.files)
    },
    setTags (tags) {
      this.forumData.tag_list = tags.join('/')
    },
    createForum () {
      const formData = new FormData(this.$refs.createForumFinal)
      for (const key in this.forumData) {
        if (key !== 'id' && this.forumData[key]) {
          // if (key === 'forum_image') {
          // if (typeof this.forum_image === 'object') formData.append('image_forum', this.forum_image)
          // } else
          if (key !== 'tag_list' && key !== 'program_url' && key !== 'forum_image') {
            formData.append(key, this.forumData[key])
          }
        }
      }
      if (formData.get('forum_program')) {
        formData.append('program_file', formData.get('forum_program'))
      }
      if (this.forumData.tag_list) {
        formData.append('tag_list', JSON.stringify(this.forumData.tag_list.split('/')))
      }

      formData.append('register_open', this.forumData.register_open)
      formData.append('questions', this.forumData.questions)

      // formData.append('about_forum_phones', JSON.stringify(this.forumData.about_forum_phones))
      formData.append('time', this.forumData.time.slice(0, 5))
      formData.append('about_forum_phones', JSON.stringify(this.forumData.about_forum_phones))
      formData.append('about_forum_emails', JSON.stringify(this.forumData.about_forum_emails))
      formData.append('news_list', JSON.stringify(this.forumData.news_list))
      console.table(...formData)
      if (this.isEdit) {
        formData.delete('image')
        formData.delete('forum_program')
        formData.delete('program_file')
        this.$store.dispatch('updateRegionalForum', { data: formData, id: this.$route.params.id })
      } else {
        console.error(this.mainForumItem)
        this.$store.dispatch('createRegionalForum', { data: formData, id: this.mainForumItem })
        this.$router.go(-1)
      }
    },

    setContentBlockType (index) {
      this.currentContentBlockType = index
    },
    setBlockContentData (data) {
      if (data.data.image && data.data.image[0]) {
        this.contentBlocks[data.index].blob = null
      }
      Object.entries(data.data).forEach(([key, value]) => {
        this.contentBlocks[data.index][key] = value
      })
    },

    setFile (file, key) {
      console.log({ file, key })
      // this.forumData.file = file
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        // this.previewBlob = null
        this[key[0]][key[1]] = file
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../assets/scss/utils/vars";

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb, .content {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }

  }

  .content-partners {
    display: flex;
    margin: 1rem;
    flex-wrap: wrap;
  }

  .content-partner {
    display: flex;
    flex-direction: column;
    margin: .5rem;

    > .input {
      margin: .5rem auto;
      width: 12.18rem;
    }

    &__remove {
      background: red;
      border: 1px solid #f00;
      border-radius: 50%;
      color: white;
      font-size: 2rem;
      height: 1.5rem;
      width: 1.5rem;
      line-height: 0.5;
      margin: 0 0 -2.1rem 10rem;
      padding: 0;
      text-align: center;
      cursor: pointer;
      z-index: 1000;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 5px;
          background-color: #EEF4FA;
        }

        &::-webkit-scrollbar-track {
          /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
        }

        &::-webkit-scrollbar-thumb {
          background-color: #246CB7;
          border-radius: 2.5px;
        }
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
